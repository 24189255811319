#quote-list {
  box-sizing: border-box;
  max-width: 800px;
  width: 100%;
  margin: auto auto;
  height: 150px;
  opacity: 1;
  -webkit-transition: height 0 linear, opacity 0.3s linear;
  -moz-transition: height 0 linear, opacity 0.3s linear;
  -ms-transition: height 0 linear, opacity 0.3s linear;
  -o-transition: height 0 linear, opacity 0.3s linear;
  transition: height 0 linear, opacity 0.3s linear;
  transition-delay: 0ms, 300ms;
  transition-property: height, opacity;
}

div.no-results {
  font-size: 18px;
  padding: 20px;
}

div.loading {
  font-size: 18px;
  padding: 20px;
}
