input.form-control[type='text'] {
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  border-radius: 2px;
  border: 1px solid rgb(221, 221, 221);
  color: rgb(0, 0, 0);
}

label {
  font-size: 1em;
  font-weight: 400;
  padding-left: 10px;
}

.form-inner {
  padding: 20px 20px 0px 20px;
}

.form-comment {
  text-align: left;
}

.form-button {
  background: rgb(85, 85, 85);
  color: rgb(255, 255, 255);
  border: 0;
  border-radius: 0;
  padding: 10px;
  font-size: 12px;
  width: 150px;
  margin: 0 10px;
  display: block;
  cursor: pointer;
}

.submit-button {
  margin-top: 10px;
  margin-bottom: 10px;
}
.cancel-button {
  margin-top: 10px;
  margin-bottom: 10px;
}

.reset-button {
  margin-top: 10px;
  margin-bottom: 10px;
}

.form-button:hover,
.form-button:focus,
.form-button:active {
  outline-color: rgb(85, 85, 85);
  outline-width: 0px;
  background: rgb(85, 85, 85);
  color: rgb(255, 255, 255);
}

.search-button {
  margin: auto auto;
}

#quote-search-container {
  box-sizing: border-box;
  max-width: 800px;
  font-size: 16px;
  margin: auto auto;
  color: rgb(255, 255, 255);
  background: rgba(0, 0, 0, 0.2);
  overflow: hidden;
  height: 150px;
  opacity: 1;
  -webkit-transition: height 0 linear, opacity 0.3s linear;
  -moz-transition: height 0 linear, opacity 0.3s linear;
  -ms-transition: height 0 linear, opacity 0.3s linear;
  -o-transition: height 0 linear, opacity 0.3s linear;
  transition: height 0 linear, opacity 0.3s linear;
  transition-delay: 0ms, 300ms;
  transition-property: height, opacity;
}

#searchResultMessage {
  color: rgb(255, 255, 255);
  max-width: 800px;
  font-size: 1.5em;
  font-weight: 400;
  text-align: center;
  margin: 10px auto;
}

@media (max-width: 500px) {
  form#quote-search-container {
    margin: 10px -15px;
  }
}

@media (max-width: 378px) {
  #quote-search-container.open {
    height: 190px;
  }
}
