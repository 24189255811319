.App {
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: 10px 30px;
}

.App-link {
  color: #61dafb;
}

main {
  margin-top: 30px;
}

.title {
  text-align: center;
  font-size: 2em;
  font-weight: 700;
  color: rgb(255, 255, 255);
}
.sub-title {
  text-align: center;
  font-size: 1.2em;
  font-weight: 700;
  margin-top: 20px;
  color: rgb(255, 255, 255);
}