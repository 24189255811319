.navigation {
  height: 35px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  background-color: #fff;
  color: #777;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
}

.brand-name {
  text-decoration: none;
  color: #777;
  font-size: 1.3rem;
  margin-left: 1rem;
}
.navigation-menu {
  margin-left: auto;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
  text-align: center;
}
.navigation-menu li {
  list-style-type: none;
  margin: 0px;
  padding: 0;
}
.navigation-menu ul li span {
  text-decoration: none;
  display: block;
  box-sizing: border-box;
  padding: 16px 45px;
  width: 100%;
}

.hamburger {
  padding: 9px 10px;
  border-radius: 50%;
  border: 1px solid transparent;
  border-color: #ddd;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}
.hamburger:hover {
  background-color: #ddd;
}

@media screen and (max-width: 768px) {
  .hamburger {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .navigation-menu ul {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .navigation-menu ul {
    position: absolute;
    top: 45px;
    left: 0;
    flex-direction: column;
    width: 100%;
    background-color: white;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    display: none;
  }
  .navigation-menu li {
    text-align: center;
    margin: 0;
  }
  .navigation-menu li a {
    position: relative;
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #777;
  }
  .navigation-menu li:hover {
    background-color: #eee;
  }
  .navigation-menu.expanded ul {
    display: block;
  }
}

.icon-bar {
  background-color: #888;
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
}

.hamburger .icon-bar + .icon-bar {
  margin-top: 4px;
}

span {
  cursor: pointer;
}

nav li span:hover,
nav li span:active
 {
  background:rgba(159, 137, 100, 0.2);
}

span.glyphicon {
  margin-right: 5px;
}