input.login-form-control {
  margin: 10px;
  padding: 10px;
  border-radius: 2px;
  border: 1px solid rgb(221, 221, 221);
  color: rgb(0, 0, 0);
  width: auto;
}

.form-control input:focus {
  border-bottom-color: #007bff;
}

.form-error {
  display: none;
  color: #dc3545;
}

.form-control.error input {
  border-bottom-color: #dc3545;
}

.form-group .form-error {
  display: block;
  margin: 10px 5px 0px 5px;
}

.form-button.submit-button {
  background: rgb(85, 85, 85);
  color: rgb(255, 255, 255);
  border: 0;
  border-radius: 0;
  padding: 10px;
  font-size: 12px;
  width: 150px;
  display: block;
  cursor: pointer;
}

.form-button.submit-button[disabled] {
  cursor: not-allowed;
}

.form-button:hover,
.form-button:focus,
.form-button:active {
  outline-color: rgb(85, 85, 85);
  outline-width: 0px;
  background: rgb(85, 85, 85);
  color: rgb(255, 255, 255);
}

#login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  max-width: 800px;
  font-size: 16px;
  margin: auto auto;
  color: rgb(255, 255, 255);
  background: rgba(0, 0, 0, 0.2);
  overflow: hidden;
  height: 200px;
  opacity: 1;
  -webkit-transition: height 0 linear, opacity 0.3s linear;
  -moz-transition: height 0 linear, opacity 0.3s linear;
  -ms-transition: height 0 linear, opacity 0.3s linear;
  -o-transition: height 0 linear, opacity 0.3s linear;
  transition: height 0 linear, opacity 0.3s linear;
  transition-delay: 0ms, 300ms;
  transition-property: height, opacity;
}

@media (max-width: 500px) {
  form#login-container {
    margin: 10px -15px;
  }
}

@media (max-width: 378px) {
  #login-container.open {
    height: 190px;
  }
}
