/* Quotes */

ul {
  margin: 0;
  padding: 0;
}

li.quote-box {
  background: rgba(0, 0, 0, 0.2);
  color: rgb(255, 255, 255);
  list-style-type: none;
  font-size: 16px;
  padding: 10px 20px;
  margin: 10px auto;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
  text-align: left;
}

span.quote-span {
  display: inline-block;
  margin: 3px 5px;
}

span.act {
  margin: 5px 3px;
}

span.scene {
  margin: 5px 3px;
}

span.quote {
  display: block;
  margin: 5px 3px;
  font-weight: 500;
}

span.tags {
  display: block;
  margin: 5px 3px;
}

span.work {
  margin: 5px 3px;
}

span.tag {
  /* background: rgba(255, 211, 145, 0.5); */
  background: rgba(0, 0, 0, 0.2);
  border: 1.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: rgb(255, 255, 255);
  display: inline-block;
  padding: 3px 10px;
  margin: 3px 5px;
  cursor: pointer;
  font-size: 0.8em;
}

div.controls {
  height: 30px;
  font-size: 0.8em;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.delete-tick {
  cursor: pointer;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: rgba(11, 12, 12, 0.3);
}
.update-tick {
  cursor: pointer;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  border: 1px solid rgba(11, 136, 0, 0.5);
  background: rgba(20, 237, 0, 0.3);
}

.tweet {
  cursor: pointer;
  border: 1px solid rgba(0, 172, 237, 0.7);
  background: rgba(0, 172, 237, 0.7);
}

.tweet .tweet-button {
  color: white;
  display: flex;
}

@media (max-width: 500px) {
  li.quote-box {
    padding: 10px 10px;
    margin: 10px -15px;
  }
}
